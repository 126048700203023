"use client";

/* eslint-disable @next/next/no-img-element */
import React from "react";
import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
  alt?: string;
  src: string;
  testid?: string;
  srcset?: string;
  innerStyle?: CSSProperties;
  notLazy?: boolean;
  customClass?: string;
}

const LazyImg = (props: Props) => {
  const { src, alt, style, testid, srcset, innerStyle, notLazy, customClass } =
    props;

  const computedStyle = { ...style, ...innerStyle };

  const [imgError, setImgError] = React.useState(false);

  if (imgError) {
    return (
      <span
        data-dummy="1"
        className={customClass}
        style={{
          ...computedStyle,
          border: "3px dashed rgba(var( --mainFgRgb), 0.12)",
          display: "block",
          borderRadius: "12px",
        }}
      ></span>
    );
  } else {
    return (
      <img
        data-testid={testid}
        src={src}
        alt={alt}
        style={computedStyle}
        srcSet={srcset}
        loading={notLazy ? "eager" : "lazy"}
        onError={() => setImgError(true)}
        className={customClass}
      ></img>
    );
  }
};
export default LazyImg;
