import React from "react";

type DropdownEmptyStateProps = {
  children: any;
};

const DropdownEmptyState = (props: DropdownEmptyStateProps) => {
  return (
    <div className="message">
      {props.children}
      <style jsx>{`
        .message {
          padding: 80px 30px;
          text-align: center;
          color: #767676;
          background: var(--mainBg);
        }
      `}</style>
    </div>
  );
};

export default DropdownEmptyState;
