import { useState, useEffect, useCallback } from "react";

export default function useDeviceDetect(defaultVal: boolean) {
  const [isMobile, setMobile] = useState(defaultVal);

  const checkMobile = useCallback(() => {
    if (typeof window === "undefined") return defaultVal;

    if (window.visualViewport) return window.visualViewport.width < 800;

    return window.innerWidth < 800;
  }, [defaultVal]);

  useEffect(() => {
    const updateMobile = () => {
      setMobile(checkMobile());
    };

    updateMobile(); // Initial check

    window.addEventListener("resize", updateMobile);

    return () => window.removeEventListener("resize", updateMobile);
  }, [checkMobile]);

  return { isMobile };
}
