export const CommonPhrases = {
  en: {
    save: "Save",
    features: "Features",
    properties: "Properties",
    tags: "Tags",
    supportedLanguages: "Supported Languages",
    reply: "reply",
    category: "Category",
    review: "Review",
    comment: "Comment",
    viewAll: "View all",
    yes: "Yes",
    no: "No",
    signIn: "Sign In",
    signUp: "Sign up",
    signOut: "Sign out",
    commentAndReviews: "Comments and Reviews",
    verifyAccount: "You need to login and / or verify your account",
    verifyAccountLong:
      "You need to verify your e-mail. Already done that? Click refresh your profile. Didn't get an email? Go to your edit profile page to send a new e-mail.",
    toastMessages: {
      sessionTimeoutHeader: "You are no longer logged in",
      sessionTimeoutBody:
        "Your session has timed out. Please click here to sign in again.",
    },
    userRank: "Level",
  },
};
