import React, { CSSProperties } from "react";

type DummyImageProps = {
  width?: number;
  height?: number;
  testId?: string;
  style?: CSSProperties;
  className?: string;
};

const DummyImage = (props: DummyImageProps) => {
  const { testId, style, className } = props;

  return (
    <div
      data-testid={testId ? testId : "dummy"}
      style={{
        borderRadius: "10px",
        border: "2px dashed rgba(var( --mainFgRgb),0.12)",
        fontSize: "460%",
        color: "rgba(var( --mainFgRgb),0.05)",
        ...style,
      }}
      className={className}
    ></div>
  );
};
export default DummyImage;
