"use client";

import React, { useContext, useEffect } from "react";
import { ToastContainer, toast, ToastPosition } from "react-toastify";
import { RootContextValues, CommonImage } from "~/typings/types";
import { RootContext } from "~/components/Contexts/RootContext";
import Heading from "~/components/Heading";
import AppIcon from "~/components/AppCommon/AppIcon";
import ServerRenderedContent from "~/components/ServerRenderedContent";
import "react-toastify/dist/ReactToastify.css";

//import "react-toastify/dist/ReactToastify.minimal.css";
//import "react-toastify/dist/ReactToastify.css";

export interface ToastMessageData {
  icon?: CommonImage;
  header?: string;
  body: string;
  linkEntireToastTo?: string;
  position?: ToastPosition;
  autoClose?: number;
}

const ToastMessage = () => {
  const rootContext = useContext<RootContextValues>(RootContext);

  const notifyError = (value: ToastMessageData) => {
    const { header, body } = value;

    toast.error(<MessageContent header={header} body={body}></MessageContent>, {
      autoClose: 5000,
      theme: "colored",
      position: "top-center",
    });

    //rootContext.setError(null);
  };

  const notifyInfo = (value: ToastMessageData) => {
    const {
      icon,
      header,
      body,
      position = "top-right",
      linkEntireToastTo,
      autoClose = 7000,
    } = value;

    toast.info(
      <MessageContent icon={icon} header={header} body={body}></MessageContent>,
      {
        position: position,
        theme: "dark",
        autoClose: autoClose,
        onClick: () => {
          if (linkEntireToastTo) {
            window.location.href = linkEntireToastTo;
          }
        },
      },
    );

    // Make sure no cookies are left behind.
    document.cookie = `edit=; path=/; domain=.alternativeto.net; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    document.cookie = `add=; path=/; domain=.alternativeto.net;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    document.cookie = `exactMatch=; path=/;domain=.alternativeto.net; expires=Thu, 01 Jan 1970 00:00:01 GMT`;

    //rootContext.setInfo(null);
  };

  useEffect(() => {
    if (rootContext.errorMessage) {
      notifyError(rootContext.errorMessage);
      rootContext.setError(null);
    }

    if (rootContext.infoMessage) {
      notifyInfo(rootContext.infoMessage);
      rootContext.setInfo(null);
    }
  }, [rootContext.errorMessage, rootContext.infoMessage]);

  return (
    <>
      <ToastContainer icon={false}></ToastContainer>
    </>
  );
};

const MessageContent = (props: ToastMessageData) => {
  const { icon, body, header } = props;

  return (
    <div data-testid="toast" style={{ display: "flex" }}>
      {icon && icon.fileName && (
        <AppIcon
          dimension={70}
          imgObject={icon}
          style={{ marginRight: "12px", marginBottom: "4px" }}
        ></AppIcon>
      )}
      <div>
        {header && (
          <Heading style={{ margin: 0 }} element="h4">
            {header}
          </Heading>
        )}
        <span
          style={{ color: "rgb(230, 230, 230)", lineHeight: 0.7 }}
          className="meta"
        >
          <ServerRenderedContent content={body}></ServerRenderedContent>
        </span>
      </div>
    </div>
  );
};

export default ToastMessage;
