"use client";

import { useTheme } from "next-themes";
import React, { useContext } from "react";
import {
  FaBars,
  FaInfo,
  FaList,
  FaPlus,
  FaQuestion,
  FaRecycle,
  FaSignInAlt,
  FaUserPlus,
} from "react-icons/fa";
import Box from "~/c/Box";
import { RootContext } from "~/c/Contexts/RootContext";
import DropdownListLink from "~/c/DropdownListLink";
import DropdownSimple from "~/c/DropdownSimple";
import IconBrightness6 from "~/c/Icons/Icons";
import useUserWithRoles from "~/helpers/hooks/useUserWithRoles";
import { CommonPhrases } from "~/helpers/phrases/common";
import { RootContextValues, UserNavItem } from "~/typings/types";

const HeaderPageMenu = () => {
  const rootContext = useContext<RootContextValues>(RootContext);

  const { setTheme, resolvedTheme } = useTheme();
  const { authUser } = useUserWithRoles();

  let toolTip = "";

  if (!authUser) {
    toolTip = "Sign in to suggest new applications";
  } else if (!authUser?.roles.includes("freezer")) {
    toolTip = CommonPhrases.en.verifyAccount;
  }

  const menuNavItems: UserNavItem[] = [
    {
      name: "Suggest new application",
      action: authUser?.roles.includes("freezer") ? "link" : "info",
      tooltip: toolTip,
      additionalInfo: "Are we missing an app? Add it!",
      url: "/manage-item/",
      nextLink: true,
      icon: <FaPlus></FaPlus>,
    },
    {
      name: "About Us",
      action: "link",
      url: `/about/`,
      nextLink: true,
      icon: <FaInfo></FaInfo>,
      additionalInfo: "More info about AlternativeTo",
    },
    {
      name: "FAQ",
      action: "link",
      url: `/faq/`,
      nextLink: true,
      icon: <FaQuestion></FaQuestion>,
      additionalInfo: "Answers to common questions",
    },
    {
      name: "Browse custom user lists",
      action: "link",
      url: `/lists/`,
      nextLink: true,
      icon: <FaList></FaList>,
      additionalInfo: "View lists created by our users",
    },
  ];

  if (resolvedTheme === "light") {
    menuNavItems.push({
      name: "Dark Mode",
      action: "event",
      url: "theme:dark",
      nextLink: false,
      icon: <IconBrightness6></IconBrightness6>,
      additionalInfo: "If it's too bright for you",
    });
  } else {
    menuNavItems.push({
      name: "Light Mode",
      action: "event",
      url: "theme:light",
      nextLink: false,
      icon: <IconBrightness6></IconBrightness6>,
      additionalInfo: "If it's too dark for you",
    });
  }

  if (rootContext.isCrewAdmin) {
    menuNavItems.splice(5, 0, {
      name: "Clear startpage cache",
      action: "link",
      icon: <FaRecycle></FaRecycle>,
      url: "/api/tools/revalidate/?path-to-revalidate=/",
      nextLink: false,
    });
  }

  if (!rootContext.user && rootContext.isMobile) {
    menuNavItems.splice(0, 0, {
      name: CommonPhrases.en.signIn,
      icon: <FaSignInAlt></FaSignInAlt>,
      action: "link",
      url: "/api/auth/login/",
      nextLink: true,
    });
    menuNavItems.splice(1, 0, {
      name: CommonPhrases.en.signUp,
      action: "link",
      url: "/api/auth/signup/",
      icon: <FaUserPlus></FaUserPlus>,
      nextLink: true,
    });
  }

  return (
    <DropdownSimple
      textLabel="Open page menu"
      label={() => <FaBars></FaBars>}
      buttonLook="dropdownHeader"
      //className="flex p-0 bg-transparent text-[var(--brandLight8)] relative text-[1.1em] hover:bg-transparent hover:[&>svg]:text-white"
      content={
        <Box sizeClass="menu">
          <DropdownListLink
            menuEvent={(url) => {
              if (url === "theme:dark") {
                setTheme("dark");
              }
              if (url === "theme:light") {
                setTheme("light");
              }
            }}
            largeMenu={true}
            items={menuNavItems}
          />
        </Box>
      }
    ></DropdownSimple>
  );
};
export default HeaderPageMenu;
