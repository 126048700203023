import React from "react";
import { CommonImage } from "~/typings/types";
import UserAvatar from "~/c/Common/UserAvatar";
import LazyImg from "~/c/LazyImg";

type ProfilePicProps = {
  //fileName?: string;
  imgObject?: CommonImage;
  width: number;
  height: number;
  renderHeight?: number;
  renderWidth?: number;
  style?: any;
  nick?: string;
};

const ProfilePic = (props: ProfilePicProps) => {
  const {
    width = 160,
    height = 160,
    nick,
    imgObject,
    renderHeight,
    renderWidth,
  } = props;

  let { style = {} } = props;

  let imageUrl = "";
  let imageRetinaUrl = "";
  const retinaDimension = width * 2;

  if (imgObject && imgObject.signedImages) {
    const signedImage = imgObject.signedImages.find(
      (x) => x.size == width + "x" + height,
    );

    const signedImageRetina = imgObject.signedImages.find(
      (x) => x.size == retinaDimension + "x" + retinaDimension,
    );

    imageRetinaUrl = signedImageRetina && signedImageRetina.signedURL;

    imageUrl = signedImage && signedImage.signedURL;
  } else {
    const colorArray = [
      "#B7AD99",
      "#8a497a",
      "#be8a6a",
      "#78878d",
      "#2464a8",
      "#48392A",
      "#C06E52",
      "#3F2A2B",
      "#AFD5AA",
      "#8C6057",
      "#324A5F",
      "#EB8258",
    ];

    if (nick && nick !== "Guest") {
      return (
        <UserAvatar
          style={{
            ...style,
          }}
          colors={colorArray}
          size={renderWidth ? renderWidth : width}
          name={nick}
        />
      );
    } else {
      let tempNick = nick;

      if (!tempNick) {
        tempNick = "Guest";
      }

      return (
        <UserAvatar
          style={{
            ...style,
          }}
          colors={colorArray}
          size={renderWidth ? renderWidth : width}
          name={tempNick}
        />
      );
    }
  }

  if (renderHeight) {
    style = { ...style, width: renderWidth, height: renderHeight };
  }

  return (
    <LazyImg
      alt={nick}
      src={imageUrl}
      srcset={`${imageRetinaUrl} 2x`}
      style={{ borderRadius: "50%", ...style }}
      testid="profile-pic-img"
    ></LazyImg>
  );
};

export default ProfilePic;
