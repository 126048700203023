import React, { useContext } from "react";

import {
  FaList,
  FaPlus,
  FaSignOutAlt,
  FaUser,
  FaUserCircle,
  FaUserCog,
} from "react-icons/fa";
import styles from "./HeaderUserMenu.module.scss"; // import your styles
import { CommonPhrases } from "~/helpers/phrases/common";
import DropdownSimple from "~/c/DropdownSimple";
import ProfilePic from "~/c/ProfilePic";
import DropdownListLink from "~/c/DropdownListLink";
import NotificationIcon from "~/c/NotificationsIcon";
import DropdownListNotifications from "~/c/DropdownListNotifications";
import useUserWithRoles from "~/helpers/hooks/useUserWithRoles";
import { RootContextValues, UserNavItem } from "~/typings/types";
import { RootContext } from "~/c/Contexts/RootContext";
import Box from "~/c/Box";
import { FaMessage } from "react-icons/fa6";

const UserMenu: React.FC = () => {
  const { authUser } = useUserWithRoles();

  const { userFromApi } = useContext<RootContextValues>(RootContext);

  let userNavItems: UserNavItem[] = [];

  if (authUser) {
    userNavItems = [
      {
        name: "Profile",
        additionalInfo: authUser.nick,
        action: "link",
        url: `/user/${authUser.urlNick}`,
        nextLink: true,
        icon: <FaUser></FaUser>,
      },
      {
        name: "Messages",
        action: "link",
        url: `/user/${authUser.urlNick}/messages`,
        additionalInfo: "Check your messages",
        nextLink: true,
        icon: <FaMessage></FaMessage>,
      },
      {
        name: "Manage account",
        action: "link",
        url: "/user/edit/#account-settings",
        nextLink: false,
        icon: <FaUserCog></FaUserCog>,
        additionalInfo: "Change your account settings",
      },

      {
        name: "Suggest new application",
        action: authUser.roles.includes("freezer") ? "link" : "info",
        tooltip: !authUser.roles.includes("freezer")
          ? CommonPhrases.en.verifyAccount
          : null,
        url: "/manage-item/",
        nextLink: true,
        icon: <FaPlus></FaPlus>,
        additionalInfo: "Are we missing an app? Add it!",
      },
      {
        name: "Create a custom list",
        action: authUser.roles.includes("freezer") ? "link" : "info",
        tooltip: !authUser.roles.includes("freezer")
          ? "You need to approve your account before you can create a custom list."
          : null,
        nextLink: false,
        url: "/lists/create/",
        icon: <FaList></FaList>,
        additionalInfo: "Create a custom list of apps",
        //url: "modal:create-list",
      },
      {
        name: CommonPhrases.en.signOut,
        icon: <FaSignOutAlt></FaSignOutAlt>,
        action: "link",
        url: "/api/auth/logout/",
        nextLink: false,
      },
    ];
  }

  return (
    <>
      {authUser && (
        <DropdownSimple
          textLabel="Open user menu"
          testId="header-account"
          buttonLook="dropdownHeader"
          //className="flex p-0 bg-transparent text-brand-light8 relative text-[1.1em] hover:bg-transparent hover:[&>svg]:text-white"
          label={() => {
            if (userFromApi) {
              const u = userFromApi;
              return (
                <ProfilePic
                  imgObject={u.image}
                  nick={u.displayName ? u.displayName : u.nick}
                  width={40}
                  height={40}
                  renderHeight={22}
                  renderWidth={22}
                  style={{
                    border: "1px solid var(--brandLight8)",
                  }}
                />
              );
            } else {
              return <FaUserCircle style={{ color: "var(--brandLight8)" }} />;
            }
          }}
          content={
            <Box sizeClass="menu">
              <DropdownListLink largeMenu={true} items={userNavItems} />
            </Box>
          }
        ></DropdownSimple>
      )}
      {authUser && (
        <DropdownSimple
          textLabel="Show Notifications"
          buttonLook="dropdownHeader"
          testId="header-notifications"
          label={() => <NotificationIcon />}
          content={<DropdownListNotifications userId={authUser.userId} />}
        ></DropdownSimple>
      )}

      {!authUser && (
        <span className={styles.authLinks}>
          <a href={`/api/auth/login/`}>{CommonPhrases.en.signIn}</a>
        </span>
      )}
    </>
  );
};

export default UserMenu;
