export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const toUrlCompatible = (str, toLower = true) => {
  const s = str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove accents
    .replace(/([^\w]+|\s+)/g, "-") // Replace space and other characters by hyphen
    .replace(/\-\-+/g, "-") // Replaces multiple hyphens by one hyphen
    .replace(/(^-+|-+$)/, "");

  if (toLower) return s.toLowerCase(); // Remove extra hyphens from beginning or end of the string

  return s;
};

export const truncate = (
  str: string,
  n: number,
  useWordBoundary?: boolean,
  stripHtml?: boolean,
  stripMarkdown?: boolean,
) => {
  if (!str) return str;

  if (stripHtml) str = str.replace(/<\/?[^>]+(>|$)/g, "");

  if (stripMarkdown) str = str.replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)/g, "$1");

  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
};

export const manualHtmlDecode = (str: string): string => {
  str =
    str &&
    str
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&")
      .replace(/&quot;/g, '"');

  str =
    str &&
    str.replace(/&#(\d+);/g, function (match, dec) {
      return String.fromCodePoint(dec);
    });

  return str;
};

// export const escapeHtml = (unsafe) => {
//   return unsafe
//     .replace(/&/g, "&amp;")
//     .replace(/</g, "&lt;")
//     .replace(/>/g, "&gt;")
//     .replace(/"/g, "&quot;")
//     .replace(/'/g, "&#039;");
// };

export const isNotEmpty = (val: string) => {
  if (!val) {
    return false;
  }

  if (val.trim().length === 0) {
    return false;
  }

  if (val.length === 0) {
    return false;
  }

  return true;
};

export const isSameGuid = (g1: string, g2: string) => {
  if (g1 && g2) {
    if (g1.toLowerCase() === g2.toLowerCase()) return true;
  }

  return false;
};

export const insertWhitespace = (
  text: string,
  maxLength: number = 15,
): string => {
  const regex = new RegExp(`([^\\s]{${maxLength}})([^\\s])`, "g");
  return text.replace(regex, `$1 $2`);
};
