"use client";

import { useRouter } from "next/navigation";
import React from "react";
import xss, { whiteList } from "xss";

interface Props {
  content: string;
  longformTypography?: boolean;
  extraParagraphPadding?: boolean;
  formatHtml?: boolean;
  noSanitize?: boolean;
  whiteSpace?: boolean;
}

const ServerRenderedContent = React.memo<Props>((props: Props) => {
  const {
    longformTypography,
    formatHtml,
    extraParagraphPadding,
    noSanitize,
    whiteSpace = true,
  } = props;
  let { content } = props;

  const router = useRouter();

  whiteList["img"] = ["src", "alt", "title", "width", "height", "class"];

  content = noSanitize ? content : xss(content, { whiteList: whiteList });

  // ### Cloudflare minify HTML can cause issues with this.
  if (longformTypography) {
    content = content && content.replace(/\r\n/g, "<br>");
  } else {
    content = content && content.replace(/\r\n/g, " ");
  }

  let cssClassName = `server-content`;

  cssClassName = extraParagraphPadding
    ? cssClassName + " extraPadding"
    : cssClassName;
  cssClassName = longformTypography ? cssClassName + " longForm" : cssClassName;
  cssClassName = formatHtml ? cssClassName + " formatHtml" : cssClassName;

  const contentClickHandler = (e) => {
    const targetLink = e.target.closest("a");
    if (!targetLink) return;
    e.preventDefault();

    router.push(targetLink.href);
  };

  return (
    <>
      <span
        style={whiteSpace ? { whiteSpace: "pre-line" } : {}}
        className={cssClassName}
        dangerouslySetInnerHTML={{ __html: content }}
        onClick={contentClickHandler}
      />
    </>
  );
});

ServerRenderedContent.displayName = "ServerRenderedContent";
export default ServerRenderedContent;
