import React, { useContext } from "react";
import { FaBell } from "react-icons/fa";
import useSWR from "swr";

import styles from "./NotificationsIcon.module.scss";
import colors from "@/scss/colors.module.scss";
import { callAPIClientSide } from "~/helpers/api";
import { RootContextValues } from "~/typings/types";
import { RootContext } from "./Contexts/RootContext";

const NotificationIcon = () => {
  const { userId } = useContext<RootContextValues>(RootContext);

  const fetcher = (url) => callAPIClientSide(url, null, "GET", userId);

  const { data, isLoading, error } = useSWR("/notifications/unread/", fetcher, {
    revalidateOnMount: true,
    revalidateOnFocus: true,
    dedupingInterval: 5000,
    retry: 3,
  });

  return (
    <div
      style={{ position: "relative" }}
      data-testid="notification-icon-wrapper"
    >
      <FaBell color={colors.brandLight8} data-testid="notification-bell-icon" />
      {!isLoading && !error && data > 0 && (
        <span
          className={styles.notificationBadge}
          data-testid="notification-badge"
        >
          {data}
        </span>
      )}
    </div>
  );
};
export default NotificationIcon;
