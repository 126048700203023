"use client";
/* eslint-disable react/prop-types */
import React, { useState, CSSProperties } from "react";
import { Popover, ArrowContainer, PopoverPosition } from "react-tiny-popover";
import Button, { ButtonLook, ButtonSize } from "./Button";
import { FaChevronDown } from "react-icons/fa";
import { cn } from "@/lib/utils";

/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['open', 'close'] }] */
import styles from "./DropdownSimple.module.scss";
import colors from "@/scss/colors.module.scss";

type DropdownSimpleProps = {
  content: any;
  buttonStyles?: CSSProperties;
  classnameOpen?: string;
  label: any;
  textLabel: string;
  arrow?: any;
  testId?: string;
  menuPosition?: PopoverPosition;
  buttonLook?: ButtonLook;
  buttonSize?: ButtonSize;
  onChange?(val: boolean): void;
  dropdownPadding?: number;
  chevronSize?: number;
  className?: string;
  isOpen?: boolean;
};

interface CustomComponentProps extends React.ComponentPropsWithoutRef<"div"> {
  onClick(): void;
}

// eslint-disable-next-line react/display-name
const CustomComponent = React.forwardRef<HTMLDivElement, CustomComponentProps>(
  (props, ref) => (
    // eslint-disable-next-line react/prop-types
    <span ref={ref} onClick={props.onClick}>
      {props.children}
    </span>
  ),
);

const DropdownSimple = (props: DropdownSimpleProps) => {
  const {
    content,
    buttonStyles,
    label,
    textLabel,
    classnameOpen,
    arrow,
    testId,
    menuPosition = "bottom",
    buttonLook = "normal",
    buttonSize,
    onChange,
    dropdownPadding = 8,
    chevronSize = 12,
    className,
    isOpen: controlledIsOpen,
  } = props;
  const [internalOpen, setInternalOpen] = useState(false);

  const dropdownOpen =
    controlledIsOpen !== undefined ? controlledIsOpen : internalOpen;

  const toggleDropdown = (val: boolean) => {
    if (controlledIsOpen === undefined) {
      setInternalOpen(val);
    }
    if (onChange) {
      onChange(val);
    }
  };

  // useEffect(() => {
  //   onChange && onChange(dropdownOpen);
  // }, [dropdownOpen]);

  function getArrowStyle(position: string, arrowSize = 10) {
    switch (position) {
      case "left":
        return {
          right: 1,
          borderLeft: `${arrowSize}px solid var(--mainBg)`,
          zIndex: 1,
        };
      case "right":
        return {
          left: 1,
          borderRight: `${arrowSize}px solid var(--mainBg)`,
          zIndex: 1,
        };
      case "bottom":
        return {
          top: 1,
          borderBottom: `${arrowSize}px solid var(--mainBg)`,
          zIndex: 1,
        };
      default:
        return {
          bottom: 1,
          borderTop: `${arrowSize}px solid var(--mainBg)`,
          zIndex: 1,
        };
    }
  }

  let separatorStyle = "";

  if (arrow) {
    separatorStyle = "hidden md:block mr-2 ml-2 border-r h-4 border-r-2";

    if (buttonLook === "cta") {
      separatorStyle += " border-cta-light dark:border-cta-light-dark";
    } else {
      separatorStyle += " border-gray-300 dark:border-gray-700";
    }
  }

  return (
    <>
      <Popover
        isOpen={dropdownOpen}
        positions={[menuPosition]}
        padding={dropdownPadding}
        containerStyle={{
          zIndex: "9999",
        }}
        reposition={true}
        onClickOutside={() => toggleDropdown(false)}
        content={({ position, childRect, popoverRect }) => {
          const arrowSize = 10;
          return (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowSize={arrowSize}
              arrowColor={colors.brandLight4}
            >
              <ArrowContainer
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowColor={colors.brandLight4}
                arrowSize={arrowSize}
                style={{
                  paddingLeft: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 0,
                }}
                arrowStyle={getArrowStyle(position, arrowSize)}
              >
                <span
                  onClick={() => {
                    toggleDropdown(false);
                  }}
                >
                  {content}
                </span>
              </ArrowContainer>
            </ArrowContainer>
          );
        }}
      >
        <CustomComponent onClick={() => toggleDropdown(!dropdownOpen)}>
          <Button
            testId={testId}
            aria-label={textLabel}
            look={buttonLook}
            size={buttonSize}
            fill={true}
            className={cn(
              className,
              arrow && "!pr-3",
              dropdownOpen && classnameOpen,
            )}
            style={buttonStyles}
          >
            {typeof label === "string" ? label : label({ dropdownOpen })}

            {arrow && (
              <span
                className={`${styles.arrow} ${
                  styles[dropdownOpen ? "open" : "close"]
                }`}
              >
                <>
                  <span className={separatorStyle} aria-hidden="true"></span>
                  <FaChevronDown size={chevronSize} />
                </>
              </span>
            )}
          </Button>
        </CustomComponent>
      </Popover>
    </>
  );
};
export default DropdownSimple;
