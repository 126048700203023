import React, { CSSProperties } from "react";
import Link from "next/link";
import { UserNavItem } from "~/typings/types";
import IconWithBorder from "~/c/Common/IconWithBorder";
import classNames from "classnames";

export type IconStyle =
  | "regular"
  | "cta"
  | "small"
  | "smaller"
  | "positive"
  | "negative"
  | "bw"
  | "round";

interface DropdownListLinkProps {
  items: UserNavItem[];
  menuEvent?(url: string): any;
  customStyles?: CSSProperties;
  iconStyle?: IconStyle;
  largeMenu?: boolean;
  showIconText?: boolean;
}

interface LinkDisplayProps {
  item: UserNavItem;
}

const LinkDisplay = ({ item }: LinkDisplayProps) => {
  const { name, additionalInfo } = item;
  return (
    <div className="flex flex-col text-main-fg font-medium pl-2">
      <span>{name}</span>
      {additionalInfo && (
        <span className="text-[var(--meta)] overflow-hidden text-ellipsis whitespace-nowrap font-normal meta">
          {additionalInfo}
        </span>
      )}
    </div>
  );
};

function LinkContent({
  item,
  iconStyle,
  showIconText,
}: {
  item: UserNavItem;
  iconStyle?: IconStyle;
  showIconText?: boolean;
}) {
  return (
    <>
      <IconWithBorder customStyle={[iconStyle]}>{item.icon}</IconWithBorder>
      {showIconText && <LinkDisplay item={item} />}
    </>
  );
}

const DropdownListLink = ({
  items,
  menuEvent,
  customStyles,
  largeMenu,
  iconStyle,
  showIconText = true,
}: DropdownListLinkProps) => {
  const getClassNameForLink = () => {
    return classNames(
      "flex items-center cursor-pointer px-2 md:px-3 py-2 md:py-2.5 bg-[var(--gray50)] hover:bg-[var(--brandLight11)] transition-colors",
      {
        "gap-3": largeMenu && showIconText,
      },
    );
  };

  const handleEventClick = (item: UserNavItem) => {
    if (item.needConfirmation) {
      if (confirm("Are you sure?")) {
        menuEvent?.(item.url);
      }
    } else {
      menuEvent?.(item.url);
    }
  };

  const renderLinkItem = (item: UserNavItem) => {
    const commonProps = {
      title: item.tooltip || undefined,
      className: getClassNameForLink(),
    };

    if (item.nextLink) {
      return (
        <Link {...commonProps} href={item.url} prefetch={false}>
          <LinkContent
            item={item}
            iconStyle={iconStyle}
            showIconText={showIconText}
          />
        </Link>
      );
    }

    return (
      <a {...commonProps} href={item.url}>
        <LinkContent
          item={item}
          iconStyle={iconStyle}
          showIconText={showIconText}
        />
      </a>
    );
  };

  const renderInfoItem = (item: UserNavItem) => (
    <span
      {...{
        title: item.tooltip || undefined,
        className: getClassNameForLink(),
        style: { opacity: "0.4" },
      }}
    >
      <LinkContent
        item={item}
        iconStyle={iconStyle}
        showIconText={showIconText}
      />
    </span>
  );

  const renderEventItem = (item: UserNavItem) => (
    <span
      onClick={() => handleEventClick(item)}
      title={item.tooltip || undefined}
      className={getClassNameForLink()}
    >
      <LinkContent
        item={item}
        iconStyle={iconStyle}
        showIconText={showIconText}
      />
    </span>
  );

  const renderItem = (item: UserNavItem) => {
    const renderers = {
      link: renderLinkItem,
      info: renderInfoItem,
      event: renderEventItem,
    };

    return (
      <li
        key={item.url}
        role="menuitem"
        className={classNames("list-none", {
          "border-b border-[var(--gray250)] last:border-b-0": !largeMenu,
        })}
      >
        {renderers[item.action]?.(item)}
      </li>
    );
  };

  const ulClasses = classNames("p-0 max-w-[400px]", {
    "border border-[var(--gray250)]": !largeMenu,
    "[&>li:first-child>*]:rounded-t-xl [&>li:last-child>*]:rounded-b-xl":
      largeMenu,
    "[&>li>*]:flex-row [&>li>*]:items-center": largeMenu && showIconText,
  });

  return (
    <ul style={customStyles} className={ulClasses} role="menu">
      {items.map(renderItem)}
    </ul>
  );
};

export default DropdownListLink;
