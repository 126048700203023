"use client";

import { useUser } from "@auth0/nextjs-auth0/client";
import { AuthUser } from "~/typings/types";

export default function useAuthUser() {
  const { user, error, isLoading } = useUser();

  let authUser: AuthUser;

  if (user) {
    authUser = {
      nick: user.nickname,
      userId: user["https://claims.alternativeto.net/alt2UserId"] as string,
      urlNick: user["https://claims.alternativeto.net/urlNick"] as string,
      roles: user["https://claims.alternativeto.net/roles"] as string[],
    };

    if (!authUser.roles) {
      authUser.roles = [];
    }
  } else {
    return { authUser, authError: error, authIsLoading: isLoading };
  }

  return { authUser, authError: error, authIsLoading: isLoading };
}
