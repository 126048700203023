import React from "react";
import styles from "./Box.module.scss";
import Heading from "~/c/Heading";
import classNames from "classnames";

interface Props {
  children?: any;
  customClass?:
    | "noDataBox"
    | "brandBox"
    | "transparentBox"
    | "listBox"
    | "listBoxStackTop"
    | "listBoxStackBottom";
  sizeClass?: "normal" | "compact" | "menu";
  title?: string | React.ReactNode;
  tools?: React.ReactNode;
  [propName: string]: any;
  noContent?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const Box: React.FC<Props> = ({
  children,
  customClass,
  title,
  tools,
  sizeClass,
  noContent,
  className,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        styles.box,
        styles[customClass],
        styles[sizeClass],
        "commonBoxList",
        className,
        { [styles.noContent]: noContent },
      )}
      {...rest}
    >
      {title && (
        <div className={styles.boxTitle}>
          {typeof title === "string" ? (
            <Heading element="h3" look="h3">
              {title}
            </Heading>
          ) : (
            title
          )}

          {tools && <div className={styles.boxTools}>{tools}</div>}
        </div>
      )}

      {children}
    </div>
  );
};

export default Box;
