// icon:brightness-6 | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconBrightness6(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1.2em"
      width="1.2em"
      {...props}
    >
      <path d="M12 18V6a6 6 0 016 6 6 6 0 01-6 6m8-2.69L23.31 12 20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69z" />
    </svg>
  );
}

export default IconBrightness6;
