import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ads"] */ "/app/components/Ads/AdPlacement.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Common/ToastMessage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Common/VerifyAccountButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdsProviderAppRouter"] */ "/app/components/Contexts/GamContext-approuter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RootContextProvider"] */ "/app/components/Contexts/RootContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProviderWrapper"] */ "/app/components/Contexts/ThemeProviderWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/HeaderLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/app/node_modules/.pnpm/@auth0+nextjs-auth0@3.5.0_next@14.2.25_@babel+core@7.26.0_babel-plugin-macros@3.1.0_rea_303883754a93f17455228fb31c61696f/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.25_next@14.2.25_@babel+core@7.26.0_babel-plugin-macros@3.1.0_r_c2c4dd43041e998ae7b3fe61383d2f7b/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.25_next@14.2.25_@babel+core@7.26.0_babel-plugin-macros@3.1.0_r_c2c4dd43041e998ae7b3fe61383d2f7b/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.25_next@14.2.25_@babel+core@7.26.0_babel-plugin-macros@3.1.0_r_c2c4dd43041e998ae7b3fe61383d2f7b/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.0_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.81.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.0_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.81.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.0_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.81.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"IBM_Plex_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"400\",\"500\"]}],\"variableName\":\"IBMPlex\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/.pnpm/nextjs-toploader@1.6.12_next@14.2.25_@babel+core@7.26.0_babel-plugin-macros@3.1.0_react_5513d00ef07bb47b0af6a5c115be62f0/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/public/static/nprogress.css");
;
import(/* webpackMode: "eager" */ "/app/public/static/global-styles.scss");
;
import(/* webpackMode: "eager" */ "/app/public/static/badges.scss");
