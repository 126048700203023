"use client";

import React from "react";
import { CommonPhrases } from "~/helpers/phrases/common";
import Button from "~/components/Button";
import useUserWithRoles from "~/helpers/hooks/useUserWithRoles";

const VerifyAccountButton = () => {
  const { authUser } = useUserWithRoles();

  if (
    authUser &&
    authUser.roles &&
    authUser.roles.find((x) => x == "tempuser") &&
    !authUser.roles.find((x) => x == "freezer")
  ) {
    return (
      <div className="container wide-container">
        <Button
          nextLink={true}
          href={`/api/auth/silent-login?returnTo=/user/edit/#account-settings`}
          style={{ display: "block", borderRadius: "0" }}
          look="warning"
          data-testid="verify-account-button"
        >
          {CommonPhrases.en.verifyAccountLong}
        </Button>
      </div>
    );
  }
};
export default VerifyAccountButton;
