import React from "react";
import {
  formatDistance,
  parseISO,
  format,
  isToday,
  isYesterday,
} from "date-fns";

type FormattedDateProps = {
  showDate: Date;
  timeAgo?: "yes" | "no" | "dynamic";
  showTime?: boolean;
  maxAYear?: boolean;
  suffix?: string;
};

const FormattedDate = (props: FormattedDateProps) => {
  const { showDate, timeAgo, showTime, maxAYear, suffix } = props;

  const makeSureDate = new Date(showDate);

  const isoString = makeSureDate.toISOString();

  const parsedDate = parseISO(isoString);

  let distanceString = formatDistance(parsedDate, new Date());

  if (maxAYear) {
    if (distanceString.indexOf("year") > 0) {
      distanceString = "more than a year";
    }
  }

  const parsedDateString = parsedDate.toString();
  const formattedDate = format(parsedDate, "PP");

  let dateString = formattedDate;

  if (timeAgo === "yes") {
    dateString = `${distanceString} ${suffix}`;
  } else if (timeAgo === "dynamic") {
    if (isToday(makeSureDate)) {
      dateString = "Today";
    } else if (isYesterday(makeSureDate)) {
      dateString = "Yesterday";
    } else {
      dateString = formattedDate;
    }

    if (showTime) {
      dateString = `${dateString} at ${format(makeSureDate, "p")}`;
    }
  } else {
    dateString = formattedDate;

    if (showTime) {
      dateString = `${dateString} at ${format(makeSureDate, "p")}`;
    }
  }

  return (
    <span suppressHydrationWarning={true} title={parsedDateString}>
      {dateString}
    </span>
  );
};

export default React.memo(FormattedDate);
