"use client";

import React, { useState, useEffect } from "react";
import { RootContextValues } from "~/typings/types";
import { ToastMessageData } from "~/components/Common/ToastMessage";
import { useRouter } from "next/navigation";
import useDeviceDetect from "~/helpers/hooks/useDeviceDetect";
import useUserWithRoles from "~/helpers/hooks/useUserWithRoles";
import { callAPIClientSide } from "~/helpers/api";

//import { getHeightGroup, getWidthGroup } from "~/helpers/commonHelper";

interface RootContextProps {
  randomSessionId?: any;
  children: any;
  isMobileDefault?: boolean;
  browserLanguagesDefault?: string[];
}

export const RootContext = React.createContext<RootContextValues>({
  isMobile: undefined,
  user: null,
  userFromApi: null,
  userId: "-1",
  setError: () => {
    // Do nothing
  },
  errorMessage: null,
  setInfo: () => {
    // Do nothing
  },
  setIntroWrapperInView: () => {
    // Do nothing
  },
  setRecaptchaToken: () => {
    // Do nothing
  },
  isInRole: () => {
    return false;
  },
  infoMessage: null,
  isCrewAdmin: false,
  recaptchaToken: "",
  browserLanguages: [],
  introWrapperInView: true,
});

export const RootContextProvider = ({
  children,
  randomSessionId,
  isMobileDefault,
  browserLanguagesDefault,
}: RootContextProps) => {
  const [error, setError] = useState<ToastMessageData>();
  const [info, setInfo] = useState<ToastMessageData>();
  const [introWrapperInView, setIntroWrapperInView] = useState(true);
  const [browserLanguages] = useState(browserLanguagesDefault);

  const { isMobile } = useDeviceDetect(isMobileDefault);

  const [randomABTestSession, setRandomABTestSession] = useState(0);
  const [userFromApi, setUserFromApi] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const { authUser } = useUserWithRoles();

  const router = useRouter();

  if (randomABTestSession === 0) {
    if (randomSessionId !== "block") {
      const sessionIdAsInt = parseInt(randomSessionId);

      if (sessionIdAsInt < 500) {
        setRandomABTestSession(1);
      } else {
        setRandomABTestSession(2);
      }
    }
  }

  useEffect(() => {
    const getUserFromApi = async () => {
      const response = await callAPIClientSide(`/users/current-user/`).catch(
        async () => {
          await router.push("/api/auth/logout/");
          // await fetch(`api/auth/login/`);

          setError({
            header: "Auth Error",
            body: "You may need to sign in again.",
          });
        },
      );

      setUserFromApi(response);
    };

    if (authUser != null) {
      getUserFromApi();
    }
  }, [authUser?.nick]);

  return (
    <RootContext.Provider
      value={{
        isMobile: isMobile,
        randomABTestSession: randomABTestSession,
        user: authUser,
        userFromApi: userFromApi,
        userId: authUser ? authUser.userId : "-1",
        isCrewAdmin: authUser && authUser.roles?.includes("administrator-plus"),
        randomSessionId: randomSessionId,

        setError: (value: string, header?: string) => {
          if (value) {
            const msg: ToastMessageData = {
              header: header ? header : "Error",
              body: value,
            };
            setError(msg);
          } else {
            setError(null);
          }
        },
        setInfo: (value: ToastMessageData) => {
          if (value) {
            setInfo(value);
          } else {
            setInfo(null);
          }
        },
        setIntroWrapperInView: (value: boolean) => {
          setIntroWrapperInView(value);
        },
        setRecaptchaToken: (value: string) => {
          setRecaptchaToken(value);
        },
        isInRole: (role: string) => {
          if (authUser && authUser.roles) {
            return authUser.roles.includes(role);
          } else {
            return false;
          }
        },
        errorMessage: error,
        infoMessage: info,
        recaptchaToken: recaptchaToken,
        browserLanguages: browserLanguages,
        introWrapperInView: introWrapperInView,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};
