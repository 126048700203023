import React, { CSSProperties, FC } from "react";
import { cn } from "~/lib/utils";
import Button from "./Button";
import { manualHtmlDecode } from "~/helpers/stringHelpers";

import styles from "./Heading.module.scss";

export type HeadingElement = "h1" | "h2" | "h3" | "h4" | "h5";

interface Props {
  element?: HeadingElement;
  look?: HeadingElement;
  separator?: boolean;
  align?: "center" | "right";
  buttonText?: string;
  buttonHref?: string;
  children: any;
  style?: CSSProperties;
  kicker?: string;
  metaHeading?: boolean;
  dynamicSize?: number;
  className?: string;
}

const Heading: FC<Props> = ({
  look,
  separator = false,
  align,
  buttonHref,
  buttonText,
  children,
  style = {},
  kicker,
  element,
  metaHeading = false,
  dynamicSize = 0,
  className,
}) => {
  element = element || look;

  const Element = element as keyof JSX.IntrinsicElements;

  const lookClass = look || element;
  const customStyle = { ...style };

  const childrenIsString = typeof children === "string";

  const showButton = buttonHref && buttonText;

  const elementClassName = cn(
    styles[lookClass],
    metaHeading && styles.metaHeading,
    align === "center" && styles.center,
    dynamicSize > 0 &&
      childrenIsString &&
      children.length > dynamicSize &&
      styles.smaller,
    dynamicSize > 0 &&
      childrenIsString &&
      children.length > dynamicSize * 2 &&
      styles.smallest,
    className,
  );

  return (
    <>
      {separator && (
        <div
          style={{ textAlign: align || "inherit" }}
          className={styles.separator}
        />
      )}
      {kicker && align !== "center" && (
        <span className={cn(styles.kicker, "meta")}>{kicker}</span>
      )}
      {kicker && align === "center" && (
        <div className={cn(styles.centerKickerContainer, "meta")}>
          <div className={styles.centerKicker}>{kicker}</div>
        </div>
      )}
      <Element translate="no" className={elementClassName} style={customStyle}>
        {childrenIsString ? <>{manualHtmlDecode(children)}</> : <>{children}</>}
        {showButton && (
          <span className={styles.buttonWrapper}>
            <Button
              style={{ display: "inline" }}
              nextLink={true}
              look="primary"
              href={buttonHref}
            >
              {buttonText}
            </Button>
          </span>
        )}
      </Element>
    </>
  );
};

export default Heading;
