import React from "react";
import Link from "next/link";
import { cn } from "@/lib/utils";

export type ButtonLook =
  | "primary"
  | "transparent"
  | "black"
  | "white"
  | "default"
  | "normal"
  | "warning"
  | "danger"
  | "cta"
  | "dropdown"
  | "dropdownHeader"
  | "success";

export type ButtonSize =
  | null
  | "icon"
  | "smallest"
  | "small"
  | "large"
  | "larger"
  | "normal";

interface Props {
  look?: ButtonLook;
  size?: ButtonSize;
  fill?: boolean;
  style?: React.CSSProperties;
  nextLink?: boolean;
  href?: string;
  title?: string;
  target?: string;
  onClick?: any;
  rel?: string;
  subText?: string;
  testId?: string;
  className?: string;
  type?: "submit" | "reset" | "button" | undefined;
  children?: React.ReactNode;
  scroll?: boolean;
  disabled?: boolean;
}

const Button: React.FC<Props> = (props) => {
  const {
    children,
    look,
    size,
    fill,
    href,
    subText,
    testId,
    nextLink,
    target,
    className,
    type,
    scroll = true,
    title,
    ...rest
  } = props;

  // Base button styles
  const baseStyles =
    "inline-flex items-center font-medium rounded-md transition-all !leading-none";

  // Size variants
  const sizeStyles = {
    icon: "text-sm px-3 md:px-6 py-2 rounded-lg",
    smallest: "text-sm py-1 px-6 flex items-center",
    small: "text-sm px-3 md:px-6 py-2",
    normal: "text-sm py-[8px] px-8",
    large: "text-base py-3 px-8",
    larger: "text-lg py-3 px-4 rounded-xl",
    null: "py-2.5 px-8",
  };

  // Look variants
  const lookStyles = {
    primary:
      "bg-top-brand border border-top-brand !text-white hover:bg-top-brand/90 disabled:bg-top-brand/50 disabled:text-white",
    transparent:
      "bg-transparent border border-gray-200 dark:border-gray-700 hover:border-gray-300",
    black:
      "bg-black text-white hover:bg-black/80 dark:bg-gray-200 dark:text-black dark:hover:bg-gray-200/80",
    white: "bg-white text-black border border-gray-100 dark:border-gray-700",
    default:
      "bg-gray-100 text-gray-900 border border-gray-200 dark:border-gray-700 hover:border-gray-300",
    normal: "bg-gray-200 text-gray-900 hover:bg-gray-100",
    warning:
      "bg-yellow-400 text-gray-900 hover:bg-yellow-500 disabled:bg-yellow-400/30 disabled:text-gray-900/50",
    danger: "bg-red-600 !text-white hover:bg-red-700 disabled:bg-red-600/50",
    cta: "bg-cta-lightest border border-cta-light !text-cta-main hover:bg-cta-main hover:!text-main-bg",
    dropdown:
      "bg-main-bg dark:bg-gray-800 border border-pop-brand dark:border-gray-700 text-top-brand dark:text-gray-400 [&>svg]:text-top-brand dark:hover:bg-gray-700 hover:bg-gray-50 dark:[&>svg]:text-gray-400",
    dropdownHeader:
      "flex p-0 bg-transparent text-brand-light8 relative text-[1.1em] hover:bg-transparent hover:[&>svg]:text-white",
    success:
      "bg-green-700 text-white hover:bg-green-800 disabled:bg-green-700/50",
  };

  // Fill style
  const fillStyle = fill ? "w-full justify-center" : "";

  // Sub text style
  const subTextElement = subText ? (
    <span className="block text-[80%] mt-1 opacity-50">{subText}</span>
  ) : null;

  const buttonStyles = cn(
    baseStyles,
    sizeStyles[size || "normal"],
    lookStyles[look || "normal"],
    fillStyle,
    subText && "flex-col",
    className,
  );

  if (href && nextLink) {
    return (
      <Link
        prefetch={false}
        title={title}
        data-testid={testId}
        className={buttonStyles}
        scroll={scroll}
        href={href}
        {...rest}
      >
        {children}
        {subTextElement}
      </Link>
    );
  }

  if (href && !nextLink) {
    return (
      <a
        title={title}
        data-testid={testId}
        target={target}
        className={buttonStyles}
        href={href}
        {...rest}
      >
        {children}
        {subTextElement}
      </a>
    );
  }

  return (
    <button
      title={title}
      type={type}
      data-testid={testId}
      className={buttonStyles}
      {...rest}
    >
      {children}
      {subTextElement}
    </button>
  );
};

export default Button;
