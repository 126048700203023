import React from "react";

import styles from "./IconWithBorder.module.scss";
import classNames from "classnames";
import { IconStyle } from "~/c/DropdownListLink";

interface IconWithBorderProps {
  children: any;
  customStyle?: IconStyle[];
  text?: string;
}

const IconWithBorder = (props: IconWithBorderProps) => {
  const { children, customStyle, text } = props;

  return (
    <span
      className={classNames(
        styles.iconWithBorder,
        {
          [styles.cta]: customStyle && customStyle.includes("cta"),
        },
        {
          [styles.small]: customStyle && customStyle.includes("small"),
        },
        {
          [styles.smaller]: customStyle && customStyle.includes("smaller"),
        },
        {
          [styles.round]: customStyle && customStyle.includes("round"),
        },
        {
          [styles.positive]: customStyle && customStyle.includes("positive"),
        },
        {
          [styles.negative]: customStyle && customStyle.includes("negative"),
        },
        {
          [styles.bw]: customStyle && customStyle.includes("bw"),
        },
        {
          [styles.text]: text,
        },
      )}
    >
      {children}
      {text && <span>&nbsp;{text}</span>}
    </span>
  );
};
export default IconWithBorder;
