"use client";

import React, { useEffect, useState } from "react";
import styles from "./DropdownListNotifications.module.scss";
import FormattedDate from "~/c/Utility/FormattedDate";
import xss from "xss";
import { callAPIClientSide } from "~/helpers/api";
import DropdownEmptyState from "~/c/DropdownEmptyState";

interface DropdownListNotificationsProps {
  userId: string;
}

const DropdownListNotifications = (props: DropdownListNotificationsProps) => {
  const { userId } = props;

  const [hasLoaded, setHasLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const getNotifications = async () => {
      try {
        const notifications: any[] = await callAPIClientSide(
          "/notifications/?markAsRead=true",
          null,
          "GET",
          userId,
        );

        const notificationsArray = Object.values(notifications).map(
          (notification) => ({
            content: notification.textDisplay,
            date: notification.notificationDate,
          }),
        );

        setItems(notificationsArray);
      } catch (err) {
        setError(err as Error);
        setItems([]);
      } finally {
        setHasLoaded(true);
      }
    };

    getNotifications();
  }, [userId]);

  if (!hasLoaded) {
    return (
      <DropdownEmptyState data-testid="notifications-loading">
        Loading...
      </DropdownEmptyState>
    );
  }

  if (error) {
    return (
      <DropdownEmptyState data-testid="notifications-error">
        Error loading notifications
      </DropdownEmptyState>
    );
  }

  if (!items.length) {
    return (
      <DropdownEmptyState data-testid="notifications-empty">
        You have no notifications
      </DropdownEmptyState>
    );
  }

  return (
    <ul className={styles.list}>
      {items &&
        items.map((notification, index) => (
          <li key={index} className={styles.listItem}>
            <div
              dangerouslySetInnerHTML={{
                __html: xss(notification.content),
              }}
            />
            <span className="meta">
              <FormattedDate
                timeAgo="yes"
                showDate={notification.date}
                suffix="ago"
              ></FormattedDate>
            </span>
          </li>
        ))}
    </ul>
  );
};
export default DropdownListNotifications;
