import React, { CSSProperties } from "react";
import { CommonImage } from "~/typings/types";
import DummyImage from "~/components/Common/DummyImage";
import LazyImg from "~/components/LazyImg";
import { cn } from "@/lib/utils";

interface Props {
  appName?: string;
  dimension: number;
  style?: CSSProperties;
  notLazy?: boolean;
  imgObject?: CommonImage;
  plainUrl?: string;
  className?: string;
}

/**
 * Shows an app's icon. The module simply returns an img tag with the right
 * 'src' and 'alt' values.
 */
const AppIcon = ({
  appName,
  dimension = 70,
  style = {},
  notLazy,
  imgObject,
  plainUrl,
  className,
}: Props) => {
  let hasImage = imgObject && imgObject.fileName;

  hasImage = hasImage || plainUrl;

  if (!hasImage) {
    return (
      <DummyImage
        testId="dummy-icon"
        style={style}
        className={className}
      ></DummyImage>
    );
  }

  let retinaDimension = dimension * 2;

  if (retinaDimension == 80) retinaDimension = 70;

  if (!className) {
    className = `w-[${dimension}px] h-[${dimension}px]`;
  }

  let signedImageUrl = "";
  let signedImageRetinaUrl = "";

  if (imgObject) {
    if (imgObject.signedImages) {
      const signedImage = imgObject.signedImages.find(
        (x) => x.size == dimension + "x" + dimension,
      );

      signedImageUrl = signedImage && signedImage.signedURL;

      const signedImageRetina = imgObject.signedImages.find(
        (x) => x.size == retinaDimension + "x" + retinaDimension,
      );

      signedImageRetinaUrl = signedImageRetina && signedImageRetina.signedURL;
    }
  } else {
    signedImageUrl = plainUrl;
    signedImageRetinaUrl = plainUrl;
  }

  const innerStyle = {
    aspectRatio: "1 / 1",
    borderRadius: undefined,
  };

  return (
    <LazyImg
      testid={`icon-${appName}`}
      src={signedImageUrl}
      srcset={`${signedImageRetinaUrl} 2x`}
      alt={
        imgObject?.description
          ? imgObject.description
          : appName
            ? appName + " icon"
            : "App icon"
      }
      style={style}
      innerStyle={innerStyle}
      notLazy={notLazy}
      customClass={cn("aspect-square", className)}
    />
  );
};

export default React.memo(AppIcon);
