"use client";

import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import React, { useContext } from "react";
import { RootContext } from "~/c/Contexts/RootContext";
import HeaderPageMenu from "~/c/HeaderPageMenu";
import HeaderUserMenu from "~/c/HeaderUserMenu";
import { RootContextValues } from "~/typings/types";
import { usePathname } from "next/navigation";

import styles from "./HeaderLinks.module.scss";
import { getUrlEntityByUrl } from "~/helpers/urls";

const SearchWrapper = dynamic(() => import("~/c/Search"), {
  ssr: false,
});

interface HeaderLinksProps {
  userLists?: any;
  hasFilterBar?: boolean;
  pageType?: string;
  hasIntroBox?: boolean;
}

const HeaderLinks = (props: HeaderLinksProps) => {
  const { pageType = false } = props;

  const { isMobile, introWrapperInView } =
    useContext<RootContextValues>(RootContext);

  const pathname = usePathname();

  const urlEntity = getUrlEntityByUrl(pathname);

  const isPageWithFilterBar =
    urlEntity === "app-alternatives" ||
    urlEntity === "app-about" ||
    urlEntity === "subcategory" ||
    urlEntity === "feature" ||
    urlEntity === "custom-browse" ||
    urlEntity === "platform" ||
    urlEntity === "tag";

  const isPageWithNoIntroBox =
    urlEntity === "tag-news" ||
    urlEntity === "news-article" ||
    pageType === "News" ||
    pageType === "ManageItem" ||
    pageType === "ManageUser" ||
    pageType === "search" ||
    pageType === undefined;

  // if (hasIntroBox) {
  //   isPageWithNoIntroBox = false;
  // }

  let hasRounded = !introWrapperInView && !isPageWithFilterBar;
  const hasStickyFilterBar = !introWrapperInView && isPageWithFilterBar;

  if (isPageWithNoIntroBox) {
    hasRounded = true;
  }

  return (
    <header
      className={`${styles.outerHeader} ${
        hasStickyFilterBar ? styles.hasStickyFilterBar : ""
      }`}
      role="banner"
    >
      <div
        className={`${styles.header} ${!introWrapperInView && styles.sticky}`}
      >
        <div
          data-testid="headerLinks"
          className={`${styles.headerLinks} ${
            hasRounded && styles.rounded
          } container wide-container`}
        >
          <div className={styles.headerLinksInner}>
            <Link
              className={styles.logoLink}
              title="Back to start page"
              href="/"
            >
              <Image
                src="/static/a2_m.svg"
                alt="AlternativeTo Logo"
                width={0}
                height={0}
                className={`${styles.logoSmall}`}
              />
              <Image
                src="/static/logo-text.svg"
                alt="AlternativeTo Logo Text"
                width={0}
                height={0}
                className={`${styles.logo} ${styles.logoLarge}`}
              />
            </Link>
            <span className={styles.globalSiteNav}>
              <nav>
                {isMobile !== undefined && (
                  <>
                    <Link prefetch={false} href="/browse/new-apps/">
                      {!isMobile ? "NEW APP RELEASES" : "NEW APPS"}
                    </Link>
                    <span>|</span>
                    <Link prefetch={false} href="/browse/all/">
                      {!isMobile ? "BROWSE ALL APPS" : "ALL APPS"}
                    </Link>
                    <span>|</span>
                    <Link prefetch={false} href="/news/all/">
                      {" "}
                      {!isMobile ? "TECH NEWS" : "NEWS"}
                    </Link>
                  </>
                )}
              </nav>
            </span>

            <div style={{ display: "flex" }} className={styles.searchWrapper}>
              <SearchWrapper
                show={!introWrapperInView || pageType !== "StartPage"}
                isMobile={isMobile}
              ></SearchWrapper>
              <span className={styles.authAndMenu}>
                <HeaderUserMenu></HeaderUserMenu>

                <HeaderPageMenu></HeaderPageMenu>
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default HeaderLinks;
