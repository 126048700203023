import React, { CSSProperties } from "react";
import initials from "initials";
import styles from "./UserAvatar.module.scss";
import { getRandomNumber } from "~/helpers/numberHelper";

interface UserAvatarProps {
  style?: CSSProperties;
  colors: string[];
  size: number;
  name: string;
}

const UserAvatar = (props: UserAvatarProps) => {
  const { style, colors, size, name } = props;

  //const [backgroundColor, setBackgroundColor] = useState(colors[0]);

  let i = 0;

  if (name == "Guest") {
    i = getRandomNumber(1, 5);
  } else {
    i = name && name.length % colors.length;
  }

  const backgroundColor = colors[i];

  let abbr = initials(name);

  if (abbr.length > 2) {
    abbr = abbr.substring(0, 2);
  }

  const innerStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: backgroundColor,
    fontSize: size / 2.4 + "px",
    width: size,
    height: size,
  };

  return (
    <div aria-label={name} style={style}>
      <div className={styles.innerAvatar} style={innerStyle}>
        {abbr}
      </div>
    </div>
  );
};
export default UserAvatar;
